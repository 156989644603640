import React from 'react'
import '../App.css';
//import { isMobile } from 'react-device-detect';

// import spacer from '../images/spacer.jpg';
import heading_animation from '../images/heading_animation.jpg';
import reel from '../images/Sample_01.mp4';

import Compost_Video_1 from '../video/EARTHWORMS_LOW_BR.mp4';
import Compost_Video_2 from '../video/HEALTHY_LOW_BR.mp4';
import Compost_Video_3 from '../video/MICROBES_LOW_BR.mp4';
import Compost_Video_4 from '../video/WATER_LOW_BR.mp4';
 
 
export default class Animation extends React.Component{
 
  render(){
    
  var new_Array = [
          <li>
            <div className='test_image' >
              <img src={heading_animation} alt='Animation and Video FX'></img>
            </div>
            
          </li>,
          <li>
            <div>
              <br></br>
              <br></br>
              <p className='about_paragraph' style={{color:'gray'}}>
                Visit GoodGlue's YouTube channel to see Kid's App animations:</p>
                <a style={{color:'orange'}} href='http://www.youtube.com/user/robot6363/videos/'>GoodGlue App Animations</a>
                <br/>
              <p className='about_paragraph' style={{color:'gray'}}>Check out the Flat Black Films website for some of my 2D animation work:</p> 
              <a style={{color:'orange'}} href='http://www.flatblackfilms.com/Flat_Black_Films/Films/Films.html'>FlatBlackFilms</a>
            </div>
          </li>,
          <li>
           <video src={reel + '#t=0.1'} width="320" height="240" controls alt='FX and Animation reel'/> 
           <p className='about_paragraph'>Misc. reel of different animation types and special effects</p>
          </li>,
          <li>
            <video src={Compost_Video_1 + '#t=0.1'} width="320" height="240" controls alt='Compost_Video_1'/> 
            <p className='about_paragraph'>In-app animation example for Educational Kids Composting project</p>
          </li>,
          <li>
            <video src={Compost_Video_2 + '#t=0.1'} width="320" height="240" controls alt='Compost_Video_2'/> 
            <p className='about_paragraph'>In-app animation example for Educational Kids Composting project</p>
          </li>,
          <li>
            <video src={Compost_Video_3 + '#t=0.1'} width="320" height="240" controls alt='Compost_Video_3'/> 
            <p className='about_paragraph'>In-app animation example for Educational Kids Composting project</p>
          </li>,
          <li>
            <video src={Compost_Video_4 + '#t=0.1'} width="320" height="240" controls alt='Compost_Video_4'/> 
            <p className='about_paragraph'>In-app animation example for Educational Kids Composting project</p>
          </li>
          

]
 

    //animation
    let animation_content = new_Array.map((item,index)=>{
      return(
        item
      )
    })

    return(
      <div ref='animation'>
        <ul className='grid-container-video'>{ animation_content }</ul>
      </div>
    )
  }



}