import React from 'react';
import './App.css';
import Dashboard from './components/Dashboard';
//import TopBar from './components/TopBar';


function App() {
 
  return (
    <div className="App">
      <Dashboard/>
    </div>
  );
}

export default App;
