import React from 'react';
//import { createRef } from 'react';
//import ReactDOM from 'react-dom'
import '../App.css';
//import Resume from './Resume';
import { isMobile } from 'react-device-detect';

import heading_design from '../images/heading_design.jpg';
 
import announce_01 from '../images/announce_01.jpg';
import announce_02 from  '../images/announce_02.jpg';
import Backpack_Character from '../images/Backpack_Character.jpg';
import Bigfoot2 from '../images/Bigfoot2.jpg';
import Block_Party_Poster from '../images/Block_Party_Poster.jpg';
import BW_cartoon from '../images/BW_cartoon.jpg';
import cartoon_01 from '../images/cartoon_01.jpg';
import Chair_Character from '../images/Chair_Character.jpg';
import Dino_1 from '../images/Dino_1.jpg';
import Diversity from '../images/Diversity.jpg';
import Emoji_hero_1 from '../images/Emoji_hero_1.jpg';
import FireBird from '../images/FireBird.jpg';
import I_forgot_girl from '../images/I_forgot_girl.jpg';
import I_forgot_guy from '../images/I_forgot_guy.jpg';
import LB_01 from '../images/LB_01.jpg';
import LB_02 from '../images/LB_02.jpg';
import LB_03 from '../images/LB_03.jpg';
import LB_04 from '../images/LB_04.jpg';
import LB_05 from '../images/LB_05.jpg';
import Mountains from '../images/Mountains.jpg';
import Poster_Illustration from '../images/Poster_Illustration.jpg';
import Protect from '../images/Protect.jpg';
import superHero_01 from '../images/superHero_01.jpg';
import superHero_02 from '../images/superHero_02.jpg';
import Trick_Em_Jigs_08_PREVIEW from '../images/Trick_Em_Jigs_08_PREVIEW.jpg';
import VisitPITTSBURGH_poster from '../images/VisitPITTSBURGH_poster.jpg';
import VisitPITTSBURGH_poster_02 from '../images/VisitPITTSBURGH_poster_2.jpg';
import Vote_for_climate_01 from '../images/Vote_for_climate_01.jpg';
import Zach_Poster from '../images/Zach_Poster.png';
import V_elevan from '../images/Bike_Club_Logo.jpg';
//import spacer from '../images/spacer.jpg';



import illo_map from '../images/illo_map.png';
import illo_reading from '../images/illo_reading.png';
import illo_scared from '../images/illo_scared.png';
import illo_thinking from '../images/illo_thinking.png';
import illo_smile from '../images/illo_smile.png';
import Simple from '../images/Simple.png';
import Simple_2 from '../images/Simple_02.png';

//const root = document.querySelector(':root');
 
let images = [
  {
    src: heading_design,
    info: ""
  },
  {
    src: illo_reading,
    info: 'Brochure Illustration',
    zoom: 'Click to Enlarge'
  },
  {
    src: illo_thinking,
    info: 'Brochure Illustration',
    zoom: 'Click to Enlarge'
  },
  {
    src: illo_smile,
    info: 'Brochure Illustration',
    zoom: 'Click to Enlarge'
  },
  {
    src: illo_scared,
    info: 'Brochure Illustration',
    zoom: 'Click to Enlarge'
  },
  {
    src: illo_map,
    info: 'Brochure Illustration',
    zoom: 'Click to Enlarge'
  },
  {
    src:Simple,
    info: 'Character Design & illustration',
    zoom: 'Click to Enlarge'
  },
  {
    src:Simple_2,
    info: 'Character Design & illustration',
    zoom: 'Click to Enlarge'
  },
  {
    src: announce_01,
    info: 'Social Media Ad',
    zoom: 'Click to Enlarge'
  },
  {
    src: announce_02,
    info: 'Social Media Ad',
    zoom: 'Click to Enlarge'
  },
  {
    src:Backpack_Character,
    info: 'Character Design & illustration',
    zoom: 'Click to Enlarge'
  },
  {
    src:Bigfoot2,
    info: 'Promotional Sticker Design & Illustration',
    zoom: 'Click to Enlarge'
  },
  {
    src:Block_Party_Poster,
    info: 'Poster Design & Illustration',
    zoom: 'Click to Enlarge'
  },
  {
    src:BW_cartoon,
    info: 'Illustration',
    zoom: 'Click to Enlarge'
     
  },
  {
    src:cartoon_01,
    info: 'Social Media Ad',
    zoom: 'Click to Enlarge'
  },
  {
    src:Chair_Character,
    info: 'Character Design & illustration',
    zoom: 'Click to Enlarge'
  },
  {
    src:Dino_1,
    info: 'Illustration',
    zoom: 'Click to Enlarge'
  },
  {
    src:Diversity,
    info: 'Social Media Ad',
    zoom: 'Click to Enlarge'
  },
  {
    src:Emoji_hero_1,
    info: 'Social Media Ad',
    zoom: 'Click to Enlarge'
  },
  {
    src:FireBird,
    info: 'Illustration',
    zoom: 'Click to Enlarge'
  },
  {
    src:I_forgot_girl,
  info: 'Social Media Ad',
  zoom: 'Click to Enlarge'
  },
  {
    src:I_forgot_guy,
    info: 'Social Media Ad',
    zoom: 'Click to Enlarge'
  },
  {
    src:LB_01,
    info: "Children's Book Illustration",
    zoom: 'Click to Enlarge'
  },
  {
    src:LB_02,
    info: "Children's Book Illustration",
    zoom: 'Click to Enlarge'
  },
  {
    src:LB_03,
    info: "Children's Book Illustration",
    zoom: 'Click to Enlarge'
  },
  {
    src:LB_04,
    info: "Children's Book Illustration",
    zoom: 'Click to Enlarge'
  },
  {
    src:LB_05,
    info: "Children's Book Illustration",
    zoom: 'Click to Enlarge'
  },
  {
    src: V_elevan,
    info: 'Logo Design',
    zoom: 'Click to Enlarge'
  },
  {
    src:Mountains,
    info: 'Social Media Ad',
    zoom: 'Click to Enlarge'
  },
  {
    src:Poster_Illustration,
    info: 'Poster Design & Illustration',
    zoom: 'Click to Enlarge'
  },
  {
    src:Protect,
    info: 'Social Media Ad',
    zoom: 'Click to Enlarge'
  },
  {
    src:superHero_01,
    info: 'Social Media Ad',
    zoom: 'Click to Enlarge'
  },
  {
    src:superHero_02,
    info: 'Social Media Ad',
    zoom: 'Click to Enlarge'
  },
  {
    src:Trick_Em_Jigs_08_PREVIEW,
    info: 'Logo Design & Illustration for Promotional Sticker',
    zoom: 'Click to Enlarge'
  },
  {
    src:VisitPITTSBURGH_poster,
    info:'Poster Design & Illustration',
    zoom:'Click to Enlarge'
  },
  {
    src:VisitPITTSBURGH_poster_02,
    info:'Poster Design & Illustration',
    zoom:'Click to Enlarge'
  },
  {
    src: Vote_for_climate_01,
    info: 'Social Media Ad',
    zoom: 'Click to Enlarge'
  },
  {
    src: Zach_Poster,
    info: 'Poster Design & Illustration',
    zoom: 'Click to Enlarge'
  },
  

]
 

export default class ImageGallery extends React.Component {

  constructor (props) {
    super(props);
    this.state = {
      show_details: null,
      show_zoom: null
    }

    this.myRef = React.createRef();
     
  }
 
  onMouseOver(event){

    if (!isMobile) {
      let index_set = null;
      let scr_id = event.id;
      images.forEach((item,index) => {
      if(item.src === scr_id){
        index_set = index;
      }
      })
      if(index_set){
        this.setState({
         show_details: index_set,
          show_zoom: null
        }) 
      } 
    }

  }

  onMouseClick(event){

    if (!isMobile) {
 
      if(event !== 'base' && !this.state.show_zoom){
        let index_set = null;
        let scr_id = event.id;
        images.forEach((item,index) => {
          if(item.src === scr_id){
            index_set = index;
          }
        })
 
        this.setState({
            show_zoom: index_set
        }) 
     
      }

      if(this.state.show_zoom){
        this.setState({
          show_zoom: null
        })
      }

    }
 
  }

  onMouseExit(){
    if(!this.state.show_zoom){
      this.setState({
        show_details: null 
      })
    }
  }

  triggerReset(){

    this.setState({
      show_zoom: null 
    })

    this.props.zoomConfirm()
 
  }
  
  render(){
    let design_illo_content = images.map((item,index)=>{
      return(
        <li key={item.src + 5}>
            {(this.state.show_details !== index && !this.state.show_zoom)
              ? <div className='item_container'
                     onMouseOver={(e) => {this.onMouseOver(e.currentTarget)}}
                     onMouseLeave={() => {this.onMouseExit()}}
                     onClick={(e) => {this.onMouseClick(e.currentTarget)}}
                     key={item.src + index}
                     id={item.src}>
                     {(index > 0)
                      ? <img className='grid_Images' key={item.src} src={item.src} alt={item.src}/>
                      : <img key={item.src} src={item.src} alt={item.src}/>
                     }
                </div> 
              : <div className='item_container'
                     onMouseLeave={() => {this.onMouseExit()}}
                     onClick={(e) => {this.onMouseClick(e.currentTarget)}}
                     key={index + 3}
                     id={item.src}>
                  <img className='grid_Images_transparent' key={item.src + 2} src={item.src} alt={item.src}/>
                  {(!this.state.show_zoom)
                    ?  
                        <p><em >{item.info}<br/> + Click to Enlarge</em> 
                        </p>
                      
                    : null
                  }
                </div>
               
            } 
            
        </li>
      )
    })
 

    let zoom_index = this.state.show_zoom;

    if (this.props.zoomReset) {

      this.triggerReset()
       
    }
 
    const gridContent = <div ref='design'>
                          <ul className='grid-container' onClick={() => {this.onMouseClick('base')}} >
                            {design_illo_content}
                          </ul>
                        </div>;
    
    return(
      <div> 
        { gridContent }
        {(this.state.show_zoom && !isMobile) 
              ? <div id={images[zoom_index].src}>
                  <div className = 'white_out'></div>
                  <div  
                       key={images[zoom_index].src + 7}
                       id={images[zoom_index].src}
                       onClick={(e) => {this.onMouseClick(e.currentTarget)}}
                  >
                    <img className='grid_Images_zoom'
                         key={images[zoom_index].src}
                         src={images[zoom_index].src}
                         alt={images[zoom_index].src}
                         onClick={(e) => {this.onMouseClick(e.currentTarget)}}
                         id={images[zoom_index].src}>
                    </img>
                  </div> 
                </div> 
              : null
        }
      </div>
    )
  };
}