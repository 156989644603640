import React, { Component } from 'react';
import Pdf from '../pdf/Randy_Cole_Design_resume.pdf';
export default class Resume extends Component {

  render() {

    return (
      <React.Fragment>
        <a className='Links'
           href = {Pdf}
           target='_blank'
           rel='noopener noreferrer'
           style={{color: 'orange', textdecoration:'none'}}
        >
          View Resume
        </a>
      </React.Fragment>
    );
  }
}