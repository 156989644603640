import React from 'react';
import './topBar.css';

export default class TopBar extends React.Component {

  // constructor (props) {
  //   super(props);
     
  //    //future view style

  // }
 

  render() {

    return(
    <div className='bar'>
      <ul className='barText'>
        <li onClick={() => this.props.navToContent('About')}>RANDY COLE</li>
        <li onClick={() => this.props.navToContent('Images')}>DESIGN / ILLUSTRATION</li>
        <li onClick={() => this.props.navToContent('Animation')}>ANIMATION</li>
        <li onClick={() => this.props.navToContent('Contact')}>CONTACT</li>
      </ul>
    </div>
    )

  }

}
    

//<a onClick={()=> {scrollToComponent(this.MyStory,{offset: 0, align: 'top', duration: 1000})}}>My Story</a>