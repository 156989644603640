import React from 'react';
import '../App.css';
//import { isMobile } from 'react-device-detect';

import heading_about from '../images/heading_about_contact_info.jpg';
// import spacer from '../images/spacer.jpg';
import Resume from './Resume';
 

export default class About extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      //future view style
    }
  }
     
    render() {


      var new_Array = [
 
        <li>
          <div>
        <h2 className='about_paragraph' style={{color:'gray'}}>Randy Cole</h2>
        <h3 className='about_paragraph'>
          <a style={{color:'orange'}} href='mailto:randy@goodglue.com'>Email Me </a>
          &nbsp;&nbsp;&nbsp;&nbsp;<Resume/>
          <br/><br/><a style={{color:'orange'}} href='https://www.rcoledev.com'>My Developer Portfolio</a>  
        </h3>
        
        <p className='about_paragraph'>
          A versatile designer/animator/developer with 25 years of experience in the graphic design,
          illustration, animation, game design and visual arts industries. At Flat Black Films lead animation
          teams and participated as a key member of a team that designed and animated feature length movies such as
          <a style={{color:'orange'}} href="http://www.flatblackfilms.com/Flat_Black_Films/Films/Pages/Waking_Life.html"> "Waking Life" </a>
          and <a style={{color:'orange'}} href="https://www.youtube.com/watch?v=hkjDUERgCQw"> "A Scanner Darkly"</a>.
          Created high profile animated advertisements
          for the award winning <a style={{color:'orange'}} href="http://www.flatblackfilms.com/Flat_Black_Films/Films/Pages/Talk_to_Chuck.html">"Talk to Chuck"</a> Charles Schwab ad campaign, the FX channel, and
          Akbank with the Flat Black Films team. As a graphic designer produced
          a wide array of both printed and digital materials, websites, packaging design, banners,
          web memes and more for clients such as NextGen, Houston Chronicle, and Funny or Die.
          Motion graphics and video work includes designing and animating the first "Machinima" music video,
          <a style={{color:'orange'}} href="https://www.youtube.com/watch?v=Cq336zk2cZk">"In the Waiting Line"</a> by the band Zero7.
          Worked as one of the Lead animators on the music video <a style={{color:'orange'}} href="https://www.youtube.com/watch?v=U_OKigBRqBI">"Destiny"</a> again for the band Zero 7.
          Created special effects for feature length movies and music videos and also worked as an art
          director on feature films, FX consultant and animation consultant for feature films, commercials and music videos.
          Most recently merged graphic design, illustration, animation and mobile app development
          into the Good Glue line of apps for children. The success of Good Glue has
          lead to a series of books, posters and products based on the Good Glue world.
          Currently working as a freelance designer/animator/developer in sunny Phoenix, AZ.
        </p>
        <br/><br/>
        <br/><br/>
        <p className='about_paragraph'>Copyright 2017 Randy Cole - Good Glue</p>
        <br/><br/>
        <br/><br/>
      </div>
        </li>

]

    return(
      <div className='grid-container-about' ref='about'>
        <div className='about_title'><img src={heading_about} alt='About'></img></div>
        <ul>{ new_Array }</ul>
      </div>
    )

    }

} 