import React from 'react'
//import { createRef } from 'react';
//import ReactDOM from 'react-dom'
import ImageGallery from './ImageGallery'
import About from './About'
import Animation from './Animation'
import TopBar from './TopBar'

export default class Dashboard extends React.Component {

  constructor(props){
      super(props)
        this.state = {
          zoom_reset: false
        }
 
    this.scrollToHref = this.scrollToHref.bind(this);
    this.reset_After_Scroll = this.reset_After_Scroll.bind(this)
  }

  scrollToHref(content){
  
    if (content === 'Images') {
      this.refs.images.scrollIntoView({block: 'start', behavior: 'smooth'})
    }

    if (content === 'Animation') {
      this.refs.animation.scrollIntoView({block: 'start', behavior: 'smooth'})
    }

    if (content === 'About') {
      this.refs.about.scrollIntoView({block: 'start', behavior: 'smooth'})
    }

    if (content === 'Contact') {
      this.refs.about.scrollIntoView({block: 'start', behavior: 'smooth'})
    }

    this.setState({
      zoom_reset: true
    })
 
  }

  reset_After_Scroll(){
    this.setState({
      zoom_reset: false
    })
  }

 
  render(){

    return(
       
      <ul >
        <li className = 'dash_list' ref='images'><ImageGallery zoomReset = {this.state.zoom_reset} zoomConfirm = {this.reset_After_Scroll}/></li>
        <li className = 'dash_list' ref='animation'><Animation /></li>
        <li className = 'dash_list' ref='about'><About /></li>
        <TopBar navToContent = {this.scrollToHref}/>
      </ul>
      
    )
  }
 
}